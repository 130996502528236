/**
 * External dependencies.
 */
import React, { useRef, useEffect, useState, useCallback } from "react"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby";
/**
 * Internal dependencies.
 */
import "./styles/_index.scss"
import Modal from 'react-bootstrap/Modal';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import ShareToFriendForm from "../../Forms/ShareToFriend";
const Beadcrumbs = (props, {
  className,
  back,
  links,
  text,
  social,
  hideSocialOnMobile = true,
  fluidContainer = false,
  hideSocialOnDesktop = false
}) => {
  const handleBack = () => { 
    window?.history?.back();
  }

      // Social share
      
      const shareurl = typeof window !== 'undefined' ? window.location.href : ''
      // const viewingBookingUrl = get(props, 'propertyData.extra.viewing_booking_url', false)
  
      const [Shareicons,setShareicons] = React.useState(false);
  
      const openShareicons = () => {
          setShareicons(true);
          if(Shareicons === true) {
          setShareicons(false);
          }
      }

      const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' +shareurl,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
        });
    }
    // Social share
  
  // console.log(props)
  // const [isOverflowing, setIsOverflowing] = useState(false)
  // const breadcrumbParentRef = useRef("")
  // const breadcrumbNavRef = useRef("")

  // const checkIsOverflowing = useCallback(() => {
  //   if (back) {
  //     return
  //   }

  //   const breadcrumbParentWidth = breadcrumbParentRef?.current?.offsetWidth
  //   const liElements = breadcrumbNavRef?.current?.lastChild?.childNodes
  //   const breadcrumbNavWidth = Array?.from(liElements)?.reduce(
  //     (acc, curr) => (acc += curr?.offsetWidth),
  //     0
  //   )

  //   setIsOverflowing(breadcrumbNavWidth > breadcrumbParentWidth)
  // }, [back])

  // useEffect(() => {
  //   if (back) {
  //     return
  //   }

  //   checkIsOverflowing()
  //   window.addEventListener("resize", checkIsOverflowing)
  //   return () => window.removeEventListener("resize", checkIsOverflowing)
  // }, [checkIsOverflowing, back])

  const data = useStaticQuery(graphql`
  query{

      glstrapi {
        allMenus(publicationState: LIVE) {
          Alias
          URL
          Label
          Main_Parent {
            Alias
            URL
            Label
          }
          Sub_Parent {
            Alias
            URL
            Label
          }
          Link_Type
        }


          pages {
              Alias
              Pagename
            }

        }
  

 

  }
`);
  const PageURL = data.glstrapi?.allMenus?.filter(item => item?.Alias === props?.alias).pop();
  var parent = ""
  var url = ""
  if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent == null)) {
    const Parent = data.glstrapi?.pages?.filter(item => item?.Alias === PageURL.Main_Parent.Alias).pop();
    parent = Parent?.Pagename
    url = PageURL?.Main_Parent?.URL

  }

  else if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent != null)) {
    const Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Main_Parent.Alias).pop();
   
    const Sub_Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Sub_Parent.Alias).pop();
    parent = Parent?.Pagename
    var sub_parent = Sub_Parent?.Pagename
    url = PageURL?.Main_Parent?.URL
    var sub_url = PageURL?.Sub_Parent?.URL
  }
  // console.log(PageURL)
  // console.log(url)
  const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
  const openSharefrndformModal = () => {
    setSharefrndformOpen(true);
  }
  const closeSharefrndformModal = () => {
    setSharefrndformOpen(false);
  }
  return (
    <div className={classNames("breadcrumbs", className)}>
      <Container 
      fluid={fluidContainer && "hg"}
      >
        {/* {props?.back ? "true" : "false"} */}
        <div className={props?.social ? "breadcrumbs__inner social-bk" : "breadcrumbs__inner"}>
          <Row className="justify-content-between flex-nowrap position-relative g-0">
            <Col
              sm="auto"
              // ref={breadcrumbParentRef}
              className={classNames("position-relative", {
                breadcrumbs__nav: !back,
                // "is-overflow": isOverflowing,
              })}
            >
              {props?.back ? (
                <button
                onClick={() => handleBack()}
                  onKeyPress={() => handleBack()}
                  className="breadcrumbs__back-btn d-flex align-items-center bg-transparent border-0 cursor-pointer"
                >
                  <i className="icon icon--arrow-left-rounded" /> Back to Search Results
                </button>
              ) : (
                // <Breadcrumb ref={breadcrumbNavRef}>
                //   <li key={0} className="d-flex align-items-center">
                //     <Link to="/">Home</Link>
                //   </li>
                //   {links.map((item, index) => (
                //     <li key={index + 1} className="d-flex align-items-center">
                //       <Link to={item.link}>{item.title}</Link>
                //     </li>
                //   ))}
                // </Breadcrumb>
                <Breadcrumb>
                  {props.Page !== 'Home' &&
                    <li className="breadcrumb-item">
                      <Link
                        to={'/'}
                        variant="none"


                      >
                        Home
                      </Link>
                    </li>
                  }
                  {PageURL?.Main_Parent != null &&
                  <li className="breadcrumb-item">
                    <Link
                      to={`/${url}/`}
                      variant="none"


                    >
                      {parent}
                    </Link>
                    </li>
                  }
                  {props.type === "property-details-page" &&
                  <li className="breadcrumb-item">
                  <a
                    // to={`/property/${props?.alias === "sales" ? 'for-sale' : 'to-rent'}`}
                    onClick={() => handleBack()}
                    onKeyPress={() => handleBack()}
                    href="javascript:void(0)"
                    variant="none"


                  >
                    {props?.alias === "sales" ? 'Property for sale' : 'Property to rent'}
                  </a>
                  </li>
                }
                 {props.type === "auction-property-details-page" &&
                  <li className="breadcrumb-item">
                  <a
                    // to={`/property/${props?.alias === "sales" ? 'for-sale' : 'to-rent'}`}
                    onClick={() => handleBack()}
                    onKeyPress={() => handleBack()}
                    href="javascript:void(0)"
                    variant="none"


                  >
                    {props?.alias === "sales" ? 'Current Auctions' : 'Property to rent'}
                  </a>
                  </li>
                }
                  
                  {props.type === "details-page" &&
                  <li className="breadcrumb-item">
                    <Link
                      to={`/${url}/${PageURL?.URL}/`}
                      variant="none"
                    >
                      {PageURL?.Label}
                    </Link>
</li>
                  }
                   {props.type === "news-details-page" &&
                  <li className="breadcrumb-item">
                    <Link
                      to={`/${PageURL?.URL}/`}
                      variant="none"
                    >
                      {PageURL?.Label}
                    </Link>
</li>
                  }
                  {PageURL?.Sub_Parent != null &&
     <li className="breadcrumb-item">
                     <Link
                      to={`/${url}/${sub_url}/`}
                      variant="none"
      
                    >
                      {sub_parent}
                    </Link>
</li>
                  }
                  <Breadcrumb.Item active>{props.Page}</Breadcrumb.Item>
                </Breadcrumb>
              )}
            </Col>
            {/* {hideSocialOnMobile ? "true" : "false"} */}
            {props?.social && (
              <Col
                sm="auto"
                className={classNames("breadcrumbs__social", {
                  "d-block d-md-none": props?.hideSocialOnDesktop,
                  "d-none d-lg-block": props?.hideSocialOnMobile,
                })}
              >
                <button onClick={openShareicons} className="d-flex align-items-center bg-transparent border-0 cursor-pointer">
                  <i className="icon icon--share me-4" />
                  <span className="d-none d-md-inline">
                    Share with a friend
                  </span>
                  <span className="d-md-none">Share</span>
                </button>
                {Shareicons &&
                                            <div onClick={openShareicons} className="property-share-social-icons2 mobile-details-socila-share">
                                            <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                            </FacebookShareButton>
                                            <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                            </TwitterShareButton>
                                            <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                            </LinkedinShareButton>
                                            <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                            </WhatsappShareButton>
                                            {props.type === "property-details-page" &&
                                            <EmailShareButton url='#' onClick={()=> { openSharefrndformModal();trackerShare('SendToFriend')}} className="my-share-button email-share">
                                                <EmailIcon size={32} round={false} borderRadius={`10`} />
                                            </EmailShareButton>
                                            }
                                            </div>
                                        }
              </Col>
            )}
          </Row>
        </div>
      </Container>
      <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={modalSharefrndformOpen} onHide={() => { closeSharefrndformModal(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Share to a friend</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                              <ShareToFriendForm
                                              id={props.data?.id}
                                               property_img={props.data?.images[0]?.url}
                                                page_url={shareurl}
                                                 property_title={props.data?.display_address}
                                                  type={props.data?.search_type}
                                              data={props?.data}
                                              
                                              />
                                              
                                            </Modal.Body>

                                        </Modal>
    </div>
  )
}

export default Beadcrumbs
